<template>
    <div>
        <el-dialog
            v-loading="loading"
            title="退店处理"
            :visible.sync="dialogVisible"
            width="40%"
            top="60px"
            :destroyOnClose="true"
            @close="close"
        >
            <el-card shadow="never" style="margin-top: 8px">
                <el-form
                    :disabled="readonlyCloseDeptBizInfoFlag() || readonlyAll"
                    @submit.native.prevent
                    ref="from"
                    label-width="200px"
                    size="small"
                >
                    <el-row>
                        <el-col>
                            <el-form-item label="机构名称：">{{ closeDeptBizDto.deptName }}</el-form-item>
                            <el-form-item label="营业执照名称：">{{ closeDeptBizDto.deptLicenseName }}</el-form-item>
                            <el-form-item label="申请人：">{{ applyStaffName }}</el-form-item>
                            <el-form-item label="申请退店时间：">{{ applyTime }}</el-form-item>
                            <el-form-item
                                label="会员转出机构："
                                v-if="
                                    (readonlyCloseDeptBizInfoFlag() && migrateMemberTargetDeptCode) ||
                                    existDeptMemberFlag
                                "
                            >
                                <template v-if="readonlyCloseDeptBizInfoFlag() && migrateMemberTargetDeptCode">
                                    {{ migrateMemberTargetDeptName }}
                                </template>
                                <!--生成申请退店数据 或 存在归属员工 时展示-->
                                <el-select v-else-if="existDeptMemberFlag" v-model="migrateMemberTargetDeptCode">
                                    <el-option label="请选择" value="" />
                                    <el-option
                                        v-for="item in storeDepts"
                                        :label="item.name"
                                        :value="item.code"
                                        :key="item.code"
                                    />
                                </el-select>
                                <div style="color: #dfe4ed">将属于本机构的会员转移到其它机构</div>
                            </el-form-item>
                            <div style="color: red">未入库/出库的单据请提前处理，退店成功后将不可处理</div>
                            <br />
                            <el-form-item
                                label="未兑换商分的分销员信息："
                                v-if="existNonZeroAmountDeptStaffDistributionListFlag"
                            >
                                <!--如果不是只读，则可以下载-->
                                <el-form v-if="!readonlyAll">
                                    <el-button type="text" @click="exportNonZeroAmountDeptStaffDistributionList">
                                        下载报表
                                    </el-button>
                                </el-form>
                            </el-form-item>
                            <el-form-item
                                label="是否清除分销员可用商分："
                                v-if="readonlyCloseDeptBizInfoFlag() || existNonZeroAmountDeptStaffDistributionListFlag"
                            >
                                <!--生成申请退店数据 或 存在可用商分 时展示-->
                                <el-radio-group v-model="clearStaffDistributionUserAmountWhenApply">
                                    <el-radio :label="true">清除</el-radio>
                                    <el-radio :label="false">不清除</el-radio>
                                </el-radio-group>
                                <div style="color: #dfe4ed">
                                    <span v-if="clearStaffDistributionUserAmountWhenApply === true">
                                        将属于该机构的所有分销员可用商分清空
                                    </span>
                                    <span v-else-if="clearStaffDistributionUserAmountWhenApply === false">
                                        请联系分销员进行兑换完所有可用商分
                                    </span>
                                </div>
                            </el-form-item>
                            <el-form-item label="未到店核销的自提订单：" v-if="existNoneCompletedStationOrdersFlag">
                                <!--如果不是只读，则可以下载-->
                                <el-form v-if="!readonlyAll">
                                    <el-button type="text" @click="exportNoneCompletedStationOrders"
                                        >下载报表
                                    </el-button>
                                </el-form>
                                <div style="color: #dfe4ed">请尽快联系顾客到店取货</div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-card>
            <div slot="footer">
                <!--如果不是只读，则显示-->
                <el-form v-if="!readonlyAll">
                    <el-button @click="dialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="applyCloseDept" v-if="canApplyFlag">申请退店</el-button>
                    <template v-else-if="canCancelCloseOrExecuteFlag">
                        <el-button type="primary" @click="cancelCloseDept">撤销退店申请</el-button>
                        <el-button type="primary" v-if="canExecuteCloseDeptFlag" @click="executeCloseDept"
                            >提交
                        </el-button>
                    </template>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { DeptTypeEnum } from 'js/DeptCommon';
import UrlUtils from 'js/UrlUtils';
import { Message, MessageBox } from 'element-ui';
import { postByFormData } from 'request/http';

const canHandleTypeEnum = { APPLY: 0, CANCEL_OR_EXECUTE: 1, NONE: 2 };

export default {
    name: 'HandleCloseDeptDialog',
    data: function () {
        return {
            dialogVisible: false,
            storeDepts: [],

            closeDeptBizDto: {
                deptCode: '',
                deptName: '',
                deptLicenseName: '',
                applyStaffCode: '',
                applyStaffName: '',
                applyTime: null,
                clearStaffDistributionUserAmountWhenApply: null,
                migrateMemberTargetDeptCode: '',
                closeProcess: null,
            },

            //退店申请、撤销和执行几种操作下的抽取出来的表单公共字段，不耦合某种操作的业务字段
            deptCode: null,
            migrateMemberTargetDeptCode: '',
            migrateMemberTargetDeptName: '',
            clearStaffDistributionUserAmountWhenApply: null,
            applyTime: null,
            applyStaffName: null,

            // 是否可以申请标志
            canApplyFlag: null,
            // 是否可以取消或者执行标志
            canCancelCloseOrExecuteFlag: null,

            existNonZeroAmountDeptStaffDistributionListFlag: false,
            existNoneCompletedStationOrdersFlag: false,
            canExecuteCloseDeptFlag: false,
            existDeptMemberFlag: false,

            readonlyAll: true,
            loading: false,
        };
    },
    mounted() {},
    computed: {},
    methods: {
        async show(deptCode, readonlyAll = false) {
            this.readonlyAll = readonlyAll;
            if (!deptCode) {
                this.$message.error('未选择机构');
                return false;
            }

            this.dialogVisible = true;
            this.loading = true;
            this.deptCode = deptCode;
            if (!this.readonlyAll) {
                this.storeDepts = await this.$efApi.deptApi.managedDeptsByDeptType(DeptTypeEnum.STORE);
                //排除掉退店机构
                this.storeDepts = this.storeDepts.filter((e) => e.code !== this.deptCode);
            }

            //查询申请退店业务数据
            await this.queryDeptCloseBiz();
            //延迟显示弹窗
            this.loading = false;
        },
        async queryDeptCloseBiz() {
            this.loading = true;
            try {
                if (!this.readonlyAll) {
                    await this.existNonZeroAmountDeptStaffDistributionList();
                    await this.canExecuteCloseDept();
                    await this.existNoneCompletedStationOrders();
                    await this.existDeptMemberList();
                }

                //查询申请退店业务数据
                this.closeDeptBizDto = (
                    await this.$http.get(`/system/closeDeptBiz/query/${this.deptCode}`)
                )?.data?.data;
                const canHandleType = this.closeProcess2CanHandleType(this.closeDeptBizDto.closeProcess);
                this.canApplyFlag = canHandleType === canHandleTypeEnum.APPLY;
                this.canCancelCloseOrExecuteFlag = canHandleType === canHandleTypeEnum.CANCEL_OR_EXECUTE;

                if (this.canApplyFlag) {
                    //如果是申请操作，则自动获取数据，并重置表单数据
                    this.applyTime = this.formatDate(new Date());
                    this.applyStaffName = this.$store.state.session.name;
                    this.migrateMemberTargetDeptCode = null;
                    this.migrateMemberTargetDeptName = null;
                    if (!this.existNonZeroAmountDeptStaffDistributionListFlag) {
                        // 如果不存在可用商分，则将是否清除商分标志设置为false
                        this.clearStaffDistributionUserAmountWhenApply = false;
                    }
                } else {
                    //申请操作之后的操作，则从响应数据中获取
                    this.applyTime = this.closeDeptBizDto.applyTime;
                    this.applyStaffName = this.closeDeptBizDto.applyStaffName;
                    this.clearStaffDistributionUserAmountWhenApply = this.closeDeptBizDto.clearStaffDistributionUserAmountWhenApply;
                    this.migrateMemberTargetDeptCode = this.closeDeptBizDto.migrateMemberTargetDeptCode;
                    this.migrateMemberTargetDeptName = this.closeDeptBizDto.migrateMemberTargetDeptName;
                }
            } finally {
                this.loading = false;
            }
        },
        close() {
            this.dialogVisible = false;
            Object.assign(this.$data, this.$options.data());
        },
        closeProcess2CanHandleType(closeProcess) {
            switch (closeProcess) {
                case 'CLOSING':
                    return canHandleTypeEnum.CANCEL_OR_EXECUTE;
                case 'CLOSED':
                case 'CLEANUP_COMPLETED':
                    return canHandleTypeEnum.NONE;
                default:
                    return canHandleTypeEnum.APPLY;
            }
        },
        async canExecuteCloseDept() {
            const rst = await this.$http.get(`/system/closeDeptBiz/canExecuteCloseDept/${this.deptCode}`, {});
            this.canExecuteCloseDeptFlag = rst?.data?.data === true;
        },
        readonlyCloseDeptBizInfoFlag() {
            return this.canApplyFlag === false;
        },
        async existNonZeroAmountDeptStaffDistributionList() {
            const rst = await this.$http.get(
                `/system/dept/queryNonZeroAmountDeptStaffDistributionCount/${this.deptCode}`
            );
            this.existNonZeroAmountDeptStaffDistributionListFlag = (rst?.data?.data || 0) > 0;
        },
        exportNonZeroAmountDeptStaffDistributionList() {
            UrlUtils.Export(this, '分销员商分信息导出', '/system/dept/exportNonZeroAmountDeptStaffDistributionList', {
                deptCode: this.deptCode,
            });
        },
        async existNoneCompletedStationOrders() {
            const rst = await this.$http.get(
                `/client/station/queryNoneCompletedStationOrderCount/` + this.deptCode,
                {}
            );
            this.existNoneCompletedStationOrdersFlag = (rst?.data?.data || 0) > 0;
        },
        async existDeptMemberList() {
            const rst = await this.$http.get(`/member/queryCountByBelongDeptCode/` + this.deptCode, {});
            this.existDeptMemberFlag = (rst?.data?.data || 0) > 0;
        },
        exportNoneCompletedStationOrders() {
            UrlUtils.Export(
                this,
                '未核销自提订单',
                '/client/station/exportNoneCompletedStationOrders/' + this.deptCode,
                {}
            );
        },
        applyCloseDept() {
            if (!this.deptCode) {
                this.$message.error('退店机构必填');
                return false;
            }
            if (this.existDeptMemberFlag && !this.migrateMemberTargetDeptCode) {
                this.$message.error('请选择会员转出机构');
                return false;
            }
            if (
                this.clearStaffDistributionUserAmountWhenApply === null ||
                this.clearStaffDistributionUserAmountWhenApply === undefined
            ) {
                this.$message.error('请选择是否清除分销员可用商分');
                return false;
            }
            MessageBox.confirm(
                `
                <ul>
                    <li>
                    若本机构分销员有可用商分且选择不清除商分，请联系分销员尽快兑换，5天内未兑换完毕，系统将自动清除；
                    </li>
                    <li>
                    若本机构在朗勤心选平台注册同城配送员，退店后配送员将禁用；
                    </li>
                    <li>
                    若本机构在朗勤心选平台开通自提业务，请联系顾客到门店核销所有自提订单，自提订单未核销完毕会影响您的退店流程；
                    </li>
                    <li>
                    若本机构在八号地综合零售云系统中存在未入库/出库的单据请提前处理，退店成功后将不可处理；
                    </li>
                    <li>
                    若本机构存在会员，请将会员指定到其它所属机构；
                    </li>
                </ul>
                `,
                '确认',
                {
                    dangerouslyUseHTMLString: true,
                    cancelButtonText: '取消',
                    confirmButtonText: '确认',
                }
            )
                .then(() => {
                    this.loading = true;
                    this.$http
                        .post(
                            '/system/closeDeptBiz/applyCloseDept',
                            {
                                deptCode: this.deptCode,
                                clearStaffDistributionUserAmountWhenApply: this
                                    .clearStaffDistributionUserAmountWhenApply,
                                migrateMemberTargetDeptCode: this.migrateMemberTargetDeptCode,
                            },
                            { timeout: 60000 * 5 }
                        )
                        .then((rst) => {
                            if (rst.data.status === 200) {
                                Message.success({ message: '申请退店成功' });
                                this.close();
                                this.$emit('afterSuccess');
                            } else {
                                Message.error({ message: '申请退店失败：' + rst.data.message });
                                this.queryDeptCloseBiz();
                            }
                        })
                        .catch(() => {
                            this.queryDeptCloseBiz();
                        })
                        .finally(() => (this.loading = false));
                })
                .catch(() => {
                    //抛出异常，中断后续代码执行，且需要return Promise
                    return Promise.reject('取消操作/关闭确认');
                });
        },
        executeCloseDept() {
            MessageBox.confirm('退店后不可恢复，是否确认退店？', '提示', {
                dangerouslyUseHTMLString: true,
                cancelButtonText: '取消',
                confirmButtonText: '确认',
            }).then(() => {
                postByFormData('/system/closeDeptBiz/executeCloseDept/' + this.deptCode, {}).then((rst) => {
                    if (rst.status === 200) {
                        Message.success({ message: '提交成功' });
                        this.close();
                        this.$emit('afterSuccess');
                    } else {
                        Message.error({ message: '提交失败：' + rst.data.message });
                    }
                });
            });
        },
        cancelCloseDept() {
            MessageBox.prompt('撤销退店原因', '确认撤销', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputValidator: function (value) {
                    return !!value;
                },
                inputErrorMessage: '请填写撤销退店原因',
            })
                .then(async ({ value }) => {
                    await postByFormData('/system/closeDeptBiz/cancelCloseDept/' + this.deptCode, {
                        cancelReason: value,
                    }).then((rst) => {
                        if (rst.status === 200) {
                            Message.success({ message: '撤销退店成功' });
                            this.close();
                            this.$emit('afterSuccess');
                        } else {
                            Message.error({ message: '撤销退店失败：' + rst.data.message });
                        }
                    });
                })
                .catch(() => {});
        },
    },
};
</script>
